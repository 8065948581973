import styled, { css } from 'styled-components';

interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'success' | 'error';
}

const variants = {
  primary: css`
    color: #ffffff;
    background-color: ${({ theme }) => theme.button};
  `,
  secondary: css`
    color: var(--color-red);
    background-color: transparent;
    outline: 1px solid var(--color-red);
  `,
  success: css`
    color: #ffffff;
    background-color: var(--color-green);
  `,
  error: css`
    color: #ffffff;
    background-color: var(--color-red);
  `,
};

const Button = styled.button<ButtonProps>`
  font-size: 12px;
  border: none;
  border-radius: var(--border-radius);
  padding: 11px 34px;
  min-width: fit-content;
  max-width: 100%;
  ${(props) => variants[props.variant!]}
`;

Button.defaultProps = {
  variant: 'primary',
};

export default Button;
