import axios from 'axios';

const logWithTimestamp = (message?: string, ...rest: any) => {
  const timestamp = new Date().toISOString();
  console.log(`[${timestamp}] ${message}`, ...rest);
};

const getCsrfToken = async () => {
  try {
    const response = await axios.get('/api/csrf-token');
    axios.defaults.headers.post['X-CSRF-Token'] = response.data.csrfToken;
  } catch (error) {
    logWithTimestamp('Error while getting CSRF Token:', error);
  }
};

await getCsrfToken();

// sending antifraudJsData = false by default
export const getTransaction = async (
  uuid?: string | undefined,
  antifraudJsData?: any,
  user_id?: string
) => {
  try {
    const res = await axios.post('/api/get-transaction', {
      uuid,
      antifraudJsData,
      user_id,
    });
    return res;
  } catch (error) {
    logWithTimestamp('Error fetching transaction:', error);
    throw error;
  }
};

// sending antifraudJsData = false by default
export const processTransaction = async (
  uuid: string | undefined,
  antifraudJsData: any = false,
  user_id: string,
  paymentTicketHistory: object[],
  isClientWasUnblocked: boolean,
  isClientBlocked: boolean
) => {
  try {
    const res = await axios.post('/api/process-transaction', {
      uuid,
      antifraudJsData,
      user_id,
      paymentTicketHistory,
      isClientWasUnblocked,
      isClientBlocked
    });
    return res;
  } catch (error) {
    console.error('Error processing transaction:', error);
    throw error;
  }
};

// sending antifraudJsData = false by default
export const declineTransaction = async (
  uuid: string | undefined,
  antifraudJsData: any = false,
  user_id: string,
  paymentTicketHistory: object[],
  isClientWasUnblocked: boolean,
  isClientBlocked: boolean
) => {
  try {
    const res = await axios.post('/api/decline-transaction', {
      uuid,
      antifraudJsData,
      user_id,
      paymentTicketHistory,
      isClientWasUnblocked,
      isClientBlocked
    });
    return res;
  } catch (error) {
    console.error('Error declining transaction:', error);
    throw error;
  }
};

// sending antifraudJsData = false by default

export const confirmTransaction = async (
  uuid: string | undefined,
  antifraudJsData: any = false,
  user_id: string,
  paymentTicketHistory: object[],
  isClientWasUnblocked: boolean,
  isClientBlocked: boolean
) => {
  try {
    const res = await axios.post('/api/confirm-transaction', {
      uuid,
      antifraudJsData,
      user_id,
      paymentTicketHistory,
      isClientWasUnblocked,
      isClientBlocked
    });
    return res;
  } catch (error) {
    console.error('Error confirming transaction:', error);
    throw error;
  }
};
