import alfa from '../assets/images/alfa.png';
import sber from '../assets/images/sber.png';
import tin from '../assets/images/tinkoff.png';
import rfs from '../assets/images/raiffeisen.png';
import visaMir from '../assets/images/visamir.png';
import yoomoney from '../assets/images/yoomoney.svg';

export const renderImage = (paymentMethod?: string) => {
  if (paymentMethod?.toUpperCase() === 'SBER') {
    return sber;
  } else if (paymentMethod?.toUpperCase() === 'TINKOFF') {
    return tin;
  } else if (paymentMethod?.toUpperCase() === 'RAIFFEISEN') {
    return rfs;
  } else if (paymentMethod?.toUpperCase() === 'MC/VISA UAH') {
    return visaMir;
  } else if (paymentMethod?.toUpperCase() === 'ALFABANK') {
    return alfa;
  } else if (paymentMethod?.toUpperCase() === 'YOOMONEY') {
    return yoomoney;
  } else {
    return;
  }
};
