import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Cancellation from '../components/Cancellation';
import Error from '../components/Error';
import Spinner from '../components/Spinner';
import Success from '../components/Success';
import {
  confirmTransaction,
  declineTransaction,
  getTransaction,
  processTransaction,
} from '../services/api';
import { styled } from 'styled-components';
import CryptoForm from '../components/CryptoForm';
import SbpForm from '../components/SbpForm';
import P2PForm from '../components/P2PForm';
import A2AForm from '../components/A2AForm';

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TransactionPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const [transaction, setTransaction] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (
      transaction?.requisite?.type === 'redirect_url' &&
      transaction?.requisite?.paymentUrl
    ) {
      handleRedirect(transaction.requisite.paymentUrl);
    }

    const clearExistingInterval = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
    const startInterval = () => {
      clearExistingInterval();
      intervalRef.current = setInterval(async () => {
        await attemptProcessAndCheckStatus();
      }, 3000);
    };

    if (transaction?.status === 'WAITING_FOR_REQUISITE') {
      startInterval();
    } else {
      clearExistingInterval();
    }

    if (!loading && transaction?.status === 'WAITING_FOR_ACTION') {
      clearExistingInterval();
      handleRedirect(transaction.redirectUrl);
    }

    return () => {
      clearExistingInterval();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, loading, uuid]);

  useEffect(() => {
    const collectAndFetchData = async () => {
      setLoading(true);
      try {
        const { getFingerprintData } = window.DataCollection;
        const fingerprintData = await getFingerprintData();

        if (uuid) {
          const response = await getTransaction(
            uuid,
            fingerprintData,
            transaction?.externalUserToken
          );

          if (response.data.success) {
            if (
              response.data.paymentRequest.requisite?.type === 'redirect_url' &&
              response.data.paymentRequest.requisite.paymentUrl
            ) {
              handleRedirect(response.data.paymentRequest.requisite.paymentUrl);
            } else {
              setTransaction(response.data.paymentRequest);
              setLoading(false);
              if (response.data.paymentRequest.status === 'CLIENT_PENDING') {
                await handleProcess();
              }
            }
          } else {
            setError(response.data.message || 'Unknown error');
            setLoading(false);
          }
        }
      } catch (error: any) {
        console.error(
          'Ошибка при сборе данных или получении транзакции:',
          error
        );
        setError(error.message);
        setLoading(false);
      }
    };

    if (uuid) {
      collectAndFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  useEffect(() => {
    if (
      transaction &&
      (transaction.status === 'WAITING_FOR_SMS' ||
        transaction.status === 'PAYMENT_PENDING')
    ) {
      const transactionDate = new Date(transaction.requisite.date);
      const endTime = new Date(transactionDate.getTime() + 10 * 60000);
      const calculateTimeLeft = () => {
        const now = new Date();
        const timeLeftInSeconds = Math.max(
          0,
          Math.floor((endTime.getTime() - now.getTime()) / 1000)
        );
        return timeLeftInSeconds;
      };

      const initialTimeLeft = calculateTimeLeft();
      setTimeLeft(initialTimeLeft);

      if (initialTimeLeft <= 0 && transaction?.redirectUrl) {
        handleRedirect(transaction.redirectUrl);
      } else {
        const startTime = localStorage.getItem('startTime');
        if (!startTime) {
          const newStartTime = Math.floor(Date.now() / 1000);
          localStorage.setItem('startTime', newStartTime.toString());
        }

        const timerInterval = setInterval(() => {
          const newTimeLeft = calculateTimeLeft();
          setTimeLeft(newTimeLeft);
          if (newTimeLeft <= 0) {
            clearInterval(timerInterval);
            localStorage.removeItem('startTime');
          }
        }, 1000);

        return () => clearInterval(timerInterval);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  useEffect(() => {
    if (
      transaction?.status === 'WAITING_FOR_SMS' ||
      transaction?.status === 'PAYMENT_PENDING'
    ) {
      const statusCheckInterval = setInterval(async () => {
        try {
          const { getFingerprintData } = window.DataCollection;
          const fingerprintData = await getFingerprintData();

          const response = await getTransaction(
            uuid,
            fingerprintData,
            transaction?.externalUserToken
          );
          if (response.data.success) {
            const updatedTransaction = response?.data?.paymentRequest;
            if (
              updatedTransaction?.paymentRequest?.requisite.type ===
                'redirect_url' &&
              updatedTransaction?.paymentRequest?.requisite.paymentUrl
            ) {
              window.location.href = response.data.paymentUrl;
            } else {
              setTransaction(updatedTransaction);
              if (updatedTransaction.status === 'COMPLETED') {
                clearInterval(statusCheckInterval);
                return (
                  <Success
                    onRedirect={() => handleRedirect(transaction.redirectUrl)}
                  />
                );
              }
              if (updatedTransaction.status === 'DECLINED') {
                clearInterval(statusCheckInterval);
                return <Cancellation onConfirm={handleConfirm} />;
              }
            }
          }
        } catch (err) {
          console.error('Error checking transaction status', err);
        }
      }, 3000);

      return () => clearInterval(statusCheckInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction, uuid]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleProcess = async () => {
    try {
      setLoading(true);
      const { getFingerprintData } = window.DataCollection;
      const fingerprintData = await getFingerprintData();

      const response = await processTransaction(
        uuid,
        fingerprintData,
        transaction?.externalUserToken,
        transaction?.paymentTicketHistory,
        transaction?.isClientWasUnblocked,
        transaction?.isClientBlocked
      );
      if (response.status === 200) {
        setTransaction(response.data.paymentRequest);
      } else if (response.status === 503) {
        return <Error onRetry={handleRetry} />;
      }
      setLoading(false);
    } catch (err) {
      setError('Error processing transaction');
      setLoading(false);
    }
  };

  const handleRetry = () => {
    setError(null);
    handleProcess();
  };

  const handleRedirect = (url: string) => {
    window.location.replace(url);
  };

  const handleCancel = async () => {
    try {
      setLoading(true);

      const { getFingerprintData } = window.DataCollection;
      const fingerprintData = await getFingerprintData();

      const response = await declineTransaction(
        uuid,
        fingerprintData,
        transaction?.externalUserToken,
        transaction?.paymentTicketHistory,
        transaction?.isClientWasUnblocked,
        transaction?.isClientBlocked
      );
      if (response.status === 200) {
        setTransaction({ ...transaction, status: 'DECLINED' });
        handleRedirect(transaction.redirectUrl);
      }
    } catch (err) {
      setError('Error cancelling transaction');
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    intervalRef.current = null;
    if (transaction.requisite.type === 'redirect_url') {
      window.location.replace(transaction.requisite.paymentUrl);
    } else {
      try {
        setLoading(true);

        const { getFingerprintData } = window.DataCollection;
        const fingerprintData = await getFingerprintData();

        const response = await confirmTransaction(
          uuid,
          fingerprintData,
          transaction?.externalUserToken,
          transaction?.paymentTicketHistory,
          transaction?.isClientWasUnblocked,
          transaction?.isClientBlocked
        );
        if (response.status === 200) {
          setTransaction({ ...transaction, status: 'COMPLETED' });
          // setTimeout(() => handleRedirect(transaction.redirectUrl), 1000);
        } else {
          setError('Error confirming transaction');
        }
        setLoading(false);
      } catch (err) {
        setError('Error confirming transaction');
        setLoading(false);
      }
    }
  };

  const attemptProcessAndCheckStatus = async () => {
    try {
      const { getFingerprintData } = window.DataCollection;
      const fingerprintData = await getFingerprintData();

      const response = await getTransaction(
        uuid,
        fingerprintData,
        transaction?.externalUserToken
      );

      if (response.data.success) {
        setTransaction(response.data.paymentRequest);
      }

      if (response.data.paymentRequest.status !== 'WAITING_FOR_REQUISITE') {
        return;
      }
    } catch (err) {
      setError('Error processing transaction');
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error onOk={() => handleRedirect(transaction.redirectUrl)} />;
  }

  if (!transaction) {
    return <Error onOk={() => handleRedirect(transaction.redirectUrl)} />;
  }

  if (transaction?.status === 'WAITING_FOR_REQUISITE') {
    return <Spinner waitingForRequisite />;
  }

  if (transaction?.status === 'DECLINED') {
    setTimeout(() => handleRedirect(transaction.redirectUrl), 5000);
    return (
      <Cancellation
        onConfirm={() => handleRedirect(transaction.redirectUrl)}
        amount={transaction.amount}
        currency={transaction.requisite.currency}
        merchantName={transaction.merchant}
        paymentMethod={transaction.paymentMethod}
        ticketId={uuid || ''}
      />
    );
  }

  if (transaction.status === 'COMPLETED') {
    return (
      <Success onRedirect={() => handleRedirect(transaction.redirectUrl)} />
    );
  }

  return (
    <>
      {loading || transaction.requisite.type === 'redirect_url' ? (
        <Spinner />
      ) : (
        <WrapperDiv>
          {(transaction.status === 'WAITING_FOR_SMS' ||
            transaction.status === 'PAYMENT_PENDING') && (
            <>
              {transaction.paymentMethod === 'CRYPTO' && (
                <CryptoForm
                  transaction={transaction}
                  ticketId={uuid || ''}
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                  timeLeft={formatTime(timeLeft || 0)}
                />
              )}
              {transaction.paymentMethod === 'SBP P2P RUB' && (
                <SbpForm
                  transaction={transaction}
                  ticketId={uuid || ''}
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                  timeLeft={formatTime(timeLeft || 0)}
                />
              )}
              {transaction.paymentMethod === 'MC/VISA/MIR P2P RUB' && (
                <P2PForm
                  transaction={transaction}
                  ticketId={uuid || ''}
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                  timeLeft={formatTime(timeLeft || 0)}
                />
              )}
              {transaction.paymentMethod === 'A2A RUB' && (
                <A2AForm
                  transaction={transaction}
                  ticketId={uuid || ''}
                  onCancel={handleCancel}
                  onConfirm={handleConfirm}
                  timeLeft={formatTime(timeLeft || 0)}
                />
              )}
            </>
          )}
        </WrapperDiv>
      )}
    </>
  );
};

export default TransactionPage;
