import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TransactionPage from './pages/TransactionPage';
import AppLayout from './ui/AppLayout';
import { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './styles/theme';
import GlobalStyles from './styles/GlobalStyles';
import useThemeOS from './services/getThemeOS';

const App = () => {
  const [theme] = useThemeOS();

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path='/:uuid' element={<TransactionPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
